@import "../../styles/variables";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 1500;

  .modalBody {
    background-color: #FFFFFF;
    color: #000000;
    height: 200px;
    max-width: 500px;
    width: 100%;
    padding: 15px;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .acceptButton {
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      border: 1px solid $light-background-color;
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      color: $light-text-color;
      &:hover {
        background-color: $light-background-color;
      }
    }
  }
}

