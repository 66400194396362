@import "../../styles/variables";

.toggleContainer {
  display: flex;
  align-items: center;

  .toggle {
    user-select: none;
    transition: all 0.2s linear;
    background-color: $dark-background-color;
    cursor: pointer;
    width: 62px;
    height: 30px;
    border: 2px solid $dark-background-color;
    position: relative;
    border-radius: 20px;
    padding: 2px;

    display: flex;
    justify-content: space-between;

    img {
      width: 22px;
      height: 22px;
    }

    .toggleIndicator {
      width: 25px;
      height: 25px;
      border-radius: 20px;
      background-color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 0;
      transition: all 0.2s linear;
      transform: translateY(-50%);
    }

    .toggleIndicatorRight {
      left: calc(100% - 25px);
      background-color: $light-background-color;
    }
  }

  .toggleDark {
    background-color: #FFFFFF;
    outline-color: #FFFFFF;
  }
}