@import "../../styles/variables";

.contentContainer {
  padding: 0 15px;

  > .title {
    text-align: center;
  }

  > .subtitle {
    text-align: center;
  }
}
