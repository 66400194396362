@import "../../styles/variables";

.contentContainer {
  > .title {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 2rem;
    @media (min-width: $grid-lg) {
      font-size: 5rem;
    }
  }

  .storiesContainer {
    .storyContainer {
      margin-bottom: 1.5rem;

      .link {
        color: $light-text-color;

        .title {
          color: inherit;
          margin: 0;
          font-size: 2rem;
        }
      }

      .linkDark {
        color: $dark-text-color;
      }
    }
  }
}