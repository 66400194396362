@import "../../styles/variables";

.contentContainer {
  text-align: center;

  > .title {
    font-size: 4rem;
    margin-bottom: 2rem;
    @media (min-width: $grid-lg) {
      font-size: 5rem;
    }
  }

  img {
    margin: auto;
    margin-bottom: 2rem;
  }

  .alertMessage {
    background-color: #FFFFFF;
    font-size: 1.2rem;
  }

  .alertMessageDark {
    color: #000000;
  }

  .linkToHome {

  }
}