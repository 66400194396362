// Light theme
$light-background-color: #C9C60A;
$light-text-color: #000000;
// Dark theme
$dark-background-color: #001e3c;
$dark-text-color: #FFFFFF;

// Breakpoints
$grid-xs: 0;
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;
$grid-xxl: 1400px;
