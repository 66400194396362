.lastNewYearStory {

  .animationContainer {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .princeWarning {
    display: inline-block;
    border: 2px dashed #FFFFFF;
    padding: 15px;
  }

  .toBeContinued {
    font-size: 2rem;
  }
}