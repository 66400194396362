@import "../../styles/variables";

.contentContainer {
  > .title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
    @media (min-width: $grid-sm) {
      font-size: 6rem;
    }
  }

  .newYearTreeContainer {
    max-width: 500px;
    margin: auto;
    margin-bottom: 1rem;
  }

  .counterContainer {
    margin-bottom: 1.5rem;
    .title {
      font-size: 4rem;
      text-align: center;
    }
  }

  .introDescriptionContainer {
    text-align: center;

    .title {
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }

    .linkToStories {
      font-size: 1.5rem;
      padding: 1rem;
      background-color: #FFFFFF;
      color: #000000;
      display: inline-block;
      border-radius: 25px;
      transition: all 0.2s linear;
      &:hover {
        text-decoration: none;
        transform: translateY(-10px);
      }
    }
  }
}